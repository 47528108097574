import React, { lazy } from "react";
import { Navigate } from "react-router-dom";
import { Redirect } from "react-router";

//Layout
//const AuthLayout = lazy(() => import("layouts/AuthLayout"));

//common
const NotFoundView = lazy(() => import("views/errors/NotFoundView"));

const Main = lazy(() => import("views/Main"));
const App = lazy(() => import("views/App"));

const routes = [
  {
    path: "/app",
    children: [
      { path: "/", element: <App /> },
      { path: "404", element: <NotFoundView /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },

  {
    path: "/",
    children: [
      { path: "/", element: <Main /> },
      { path: "404", element: <NotFoundView /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
];

export default routes;
