import './App.css';
import React, { lazy, Suspense } from 'react';
import { useRoutes } from 'react-router-dom';

import Loading from './components/Loading';
import routes from './routes';

function App() {
  const routing = useRoutes(routes );
  return (
    <Suspense fallback={<Loading/>}  >
        {routing}
    </Suspense>
  );
}

export default App;
